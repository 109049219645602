/* src/styles/HomePage.css */
.homepage {
  padding: 20px;
}

.medicine-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}
