/* src/styles/index.css */

:root {
  --primary-color: #28a745;
  --secondary-color: #333;
  --text-color: #212529;
  --background-color: #f8f9fa;
}

body {
  font-family: Arial, sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}
