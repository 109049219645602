/* src/styles/SearchBar.css */
.search-bar-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
}

.suggestions-list {
  position: absolute;
  width: 100%;
  max-height: 200px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 0 0 5px 5px;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
