/* src/styles/ProductPage.css */
.product-page {
  padding: 20px;
  text-align: center;
}

.price-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2em;
  margin: 10px 0;
}

.original-price {
  text-decoration: line-through;
  color: #888;
}

.discounted-price {
  font-weight: bold;
  color: #28a745; /* Green color to indicate discount */
}

.discount-percent {
  color: #28a745;
  font-size: 0.9em;
}
